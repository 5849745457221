import React, { FunctionComponent } from "react";
import { useMount } from "react-use";
import { goToPage } from "../goToPage";
import { MetaInfo } from "../components/MetaInfo";

// TODO: redirect by auth
const IndexPage: FunctionComponent = () => {
  useMount(() => {
    goToPage("/login");
  });

  return (
    <>
      <MetaInfo />
      <div> Hello</div>
    </>
  );
};

export default IndexPage;

import React from "react";
import { FunctionComponent } from "react";

export const MetaInfo: FunctionComponent = ({}) => {
  return (
    <>
      <title>Message Hub</title>
    </>
  );
};
